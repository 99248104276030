import { Component, Vue } from 'nuxt-property-decorator'
import { ActionType, namespace } from '~/store/modules/redirect'

@Component
export default class SaveRouteMixin extends Vue {
  mounted () {
    const baseNameFrom = this.getRouteBaseName(this.$route)

    this.$store.dispatch(`${namespace}/${ActionType.SET_REDIRECTED_FROM}`, {
      name: baseNameFrom,
      query: this.$route.query,
      params: this.$route.params,
    })
  }
}
