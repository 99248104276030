
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { getCookie, setCookie } from '@alao-frontend/utils'
import AlaoLogoWhite from '~/assets/images/icons/alao-logo-white.svg?inline'

import IconCrownSimple from '~/assets/images/icons/crown-simple.svg?inline'
import { OnMounted } from '~/core/vue.types'

const COOKIE_NAME = 'best_deals_guarantee_dismissed'
const COOKIE_EXP = 7 // one week

const EVENT_SENDING_DELAY = 1000
let timeout: ReturnType<typeof setTimeout>

@Component({
  components: {
    AlaoLogoWhite,
    IconCrownSimple,
  },
})
export default class BestDealGuarantee extends Vue implements OnMounted {
  isShowCaption = false
  isShow = false
  isCaptionWasVisible = false

  @Prop({ type: Boolean, default: true, required: false })
  readonly autoResize!: boolean

  @Prop({ type: Boolean, default: true, required: false })
  readonly caption!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  readonly big!: boolean

  onShieldClick () {
    if (this.isShowCaption) {
      this.hideCaption()
      return
    }
    this.showCaption()
  }

  showCaption () {
    this.isShowCaption = true
  }

  hideCaption () {
    this.isShowCaption = false
    this.isCaptionWasVisible = true
  }

  onDismissed () {
    this.isShow = false
    setCookie(COOKIE_NAME, 1, { expires: COOKIE_EXP })
  }

  onCTAClick () {
    const btnText = this.$t('home.findYourPlan') as string

    this.$analytics.gtm.sendQuizCTAClickEvent(this.$i18n.locale, btnText)

    this.$router.push(this.localePath('quiz'))
  }

  onMouseEnter () {
    timeout = setTimeout(() => {
      this.$analytics.gtm.sendBestDealGuaranteeViewEvent('view-description', this.$route.fullPath)
    }, EVENT_SENDING_DELAY)
  }

  onMouseLeave () {
    if (timeout) {
      clearTimeout(timeout)
    }
  }

  get isDismissed () {
    return !!getCookie(COOKIE_NAME)
  }

  mounted () {
    if (this.isDismissed) {
      return
    }
    this.isShow = true
    this.$analytics.gtm.sendBestDealGuaranteeViewEvent('view-badge', this.$route.fullPath)
  }
}
