var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isShow)?_c('div',{class:['best-deal-guarantee', {
    'best-deal-guarantee--mobile': _vm.autoResize,
    'best-deal-guarantee--big': _vm.big,
  }]},[_c('div',{staticClass:"best-deal-guarantee__shield",attrs:{"data-e2e":"bestDealGuarantee"},on:{"click":_vm.onShieldClick,"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave}},[(_vm.isCaptionWasVisible)?_c('button',{staticClass:"best-deal-guarantee__btn-close mobile--show",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.onDismissed.apply(null, arguments)}}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"best-deal-guarantee__shield-header"},[_c('AlaoLogoWhite',{staticClass:"best-deal-guarantee__shield-logo"})],1),_vm._v(" "),_c('div',{staticClass:"best-deal-guarantee__shield-content"},[(_vm.$i18n.locale !== 'fr')?[_c('div',{staticClass:"best-deal-guarantee__text best-deal-guarantee__text--small"},[_vm._v("\n          "+_vm._s(_vm.$t('widgets.bestDealGuarantee.subtitle'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"best-deal-guarantee__text best-deal-guarantee__text--big"},[_vm._v("\n          "+_vm._s(_vm.$t('widgets.bestDealGuarantee.title'))+"\n        ")])]:[_c('div',{staticClass:"best-deal-guarantee__text best-deal-guarantee__text--big"},[_vm._v("\n          "+_vm._s(_vm.$t('widgets.bestDealGuarantee.title'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"best-deal-guarantee__text best-deal-guarantee__text--small"},[_vm._v("\n          "+_vm._s(_vm.$t('widgets.bestDealGuarantee.subtitle'))+"\n        ")])]],2)]),_vm._v(" "),(_vm.caption)?_c('div',{class:[
      'best-deal-guarantee__caption-wrapper',
      { 'best-deal-guarantee__caption-wrapper--open': _vm.isShowCaption }
    ]},[_c('div',{staticClass:"best-deal-guarantee__caption"},[_c('button',{staticClass:"best-deal-guarantee__btn-close mobile--show",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.hideCaption()}}}),_vm._v(" "),_c('div',{staticClass:"best-deal-guarantee__caption-content",domProps:{"innerHTML":_vm._s(_vm.$t('widgets.bestDealGuarantee.captionHtml'))}}),_vm._v(" "),_c('AlaoButton',{staticClass:"best-deal-guarantee__cta text--center",attrs:{"href":_vm.localePath('quiz'),"size":"sm","data-e2e":"bestDealGuaranteeBtn"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onCTAClick()}}},[_vm._v("\n        "+_vm._s(_vm.$t('home.findYourPlan'))+"\n      ")])],1)]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }